import React from "react";
import Masonry from "react-masonry-css";

interface ProjectGalleryProps {}

const ProjectGallery: React.FC<ProjectGalleryProps> = ({ children }) => {
  const breakpointColumnsObj = {
    default: 3,
    768: 2,
    640: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="flex flex-wrap"
      columnClassName="px-2 bg-clip-padding"
    >
      {children}
    </Masonry>
  );
};

export default ProjectGallery;
