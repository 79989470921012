import React from "react";

interface SectionIntroProps {
  subHeading: string;
  heading: string;
  className?: string;
}

const SectionIntro: React.FC<SectionIntroProps> = ({
  subHeading,
  heading,
  className = "",
  children,
}) => {
  return (
    <div className={className}>
      <h3 className="mb-0 sm:text-lg text-base text-primary-main font-medium">
        {subHeading}
      </h3>
      <h2 className="sm:text-3xl text-2xl mb-2 max-w-xl">{heading}</h2>
      {children}
    </div>
  );
};

export default SectionIntro;
