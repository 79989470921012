import { Button } from "@material-ui/core";
import {
  Check,
  EmailOutlined,
  LocationOnOutlined,
  PhoneAndroidOutlined,
} from "@material-ui/icons";
import { graphql, Link } from "gatsby";
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/ui/Contact/ContactForm/ContactForm";
import ContactInfoItem from "../components/ui/Contact/ContactInfo/ContactInfoItem";
import ProjectGallery from "../components/ui/ProjectGallery/ProjectGallery";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";
import contactInfo from "../content/settings/contact_info.json";

interface ServicePageTemplateProps {
  pageContext: {
    serviceName: string;
  };
  data: {
    projectGallery?: Images;
    imagesAboutService?: Images;
    imagesExpertise?: Images;
  };
}

type Images = {
  nodes: {
    relativePath: string;
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
      original: {
        src: string;
      };
    };
  }[];
};

export interface servicePageData {
  name: string;
  seo: {
    seoTitle: string;
    slug: string;
    metaDescription?: string;
  };
  topSection: {
    headline: string;
  };
  aboutService: {
    subHeadline: string;
    headline: string;
    serviceDescription: string;
    buttonText: string;
    images: {
      src: string;
      alt: string;
    }[];
  };
  serviceExpertise?: {
    subHeadline: string;
    headline: string;
    description: string;
    expertise: {
      name: string;
      linkedService?: string;
    }[];
    image: {
      src: string;
      alt: string;
    };
  }[];
  projects?: {
    subHeadline: string;
    headline: string;
    description: string;
    images: {
      src: string;
      alt: string;
    }[];
  }[];
}

const ServicePageTemplate: React.FC<ServicePageTemplateProps> = ({
  pageContext,
  data,
}) => {
  const serviceData: servicePageData = require(`../content/pages/${pageContext.serviceName}`);

  const serviceExpertise = serviceData.serviceExpertise?.length
    ? serviceData.serviceExpertise[0]
    : null;

  return (
    <Layout>
      <SEO
        title={serviceData.seo.seoTitle}
        description={serviceData.seo.metaDescription || ""}
        slug={serviceData.seo.slug}
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white mb-0 leading-none">
            {serviceData.topSection.headline}
          </h1>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start lg:items-center justify-items-center">
            <div
              className={`grid ${
                data.imagesAboutService?.nodes.length === 1
                  ? "lg:grid-rows-1 grid-cols-1 lg:grid-cols-none"
                  : "lg:grid-rows-2 grid-cols-2 lg:grid-cols-none"
              } gap-4 lg:order-last w-full`}
            >
              {data.imagesAboutService ? (
                data.imagesAboutService.nodes.map((image, index) =>
                  image ? (
                    <GatsbyImage
                      image={image.childImageSharp.gatsbyImageData}
                      alt={serviceData.aboutService.images[index].alt}
                      key={`aboutImage-${index}`}
                    />
                  ) : null
                )
              ) : (
                <>
                  <StaticImage
                    src="../images/homepage-image-1.jpg"
                    alt="About"
                    layout="fullWidth"
                  />

                  <StaticImage
                    src="../images/homepage-image-1.jpg"
                    alt="About"
                    layout="fullWidth"
                  />
                </>
              )}
            </div>
            <div className="max-w-xl order-last lg:order-none">
              <SectionIntro
                subHeading={serviceData.aboutService.subHeadline}
                heading={serviceData.aboutService.headline}
              />

              {serviceData.aboutService.serviceDescription.split(/\n/g).map(
                (paragraph, index) =>
                  !!paragraph && (
                    <p key={`about-service-p-${index}`} className="mb-2">
                      {paragraph}
                    </p>
                  )
              )}

              <Button
                href="#contact"
                className="rounded-none font-semibold mt-2 min-h-[3rem]"
                variant="contained"
                color="primary"
                size="large"
                disableElevation
              >
                {serviceData.aboutService.buttonText}
              </Button>
            </div>
          </div>
        </div>
      </section>

      {serviceExpertise ? (
        <section className="odd:bg-gray-50">
          <div className="container">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:items-center justify-items-center gap-16">
              <div className="max-w-2xl w-full hidden lg:block">
                {data.imagesExpertise?.nodes.map((image, index) =>
                  image ? (
                    <GatsbyImage
                      image={image.childImageSharp.gatsbyImageData}
                      alt={serviceExpertise.image.alt}
                      key={`aboutExpertise-${index}`}
                    />
                  ) : null
                )}
              </div>
              <div className="max-w-xl lg:justify-self-end">
                <SectionIntro
                  subHeading={serviceExpertise.subHeadline}
                  heading={serviceExpertise.headline}
                />

                <p className="mb-4">{serviceExpertise.description}</p>

                <ul className="grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-[repeat(7,min-content)] auto-rows-min sm:grid-flow-col max-w-lg ">
                  {serviceExpertise.expertise.map((service, index) => (
                    <li
                      key={`${service.name}-${index}`}
                      className="font-medium flex items-center mb-1 last:mb-0"
                    >
                      <Check />
                      {service.linkedService ? (
                        <Link
                          to={`/services/${service.linkedService}`}
                          className="ml-1 text-text-heading hover:text-primary-main"
                        >
                          {service.name}
                        </Link>
                      ) : (
                        <span className="ml-1">{service.name}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {serviceData.projects &&
      typeof serviceData.projects[0] !== "undefined" ? (
        <section>
          <div className="container">
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 ">
              <SectionIntro
                subHeading={serviceData.projects[0].subHeadline}
                heading={serviceData.projects[0].headline}
                className="col-span-full text-center mb-8 justify-self-center"
              >
                <p className="max-w-lg mx-auto">
                  {serviceData.projects[0].description}
                </p>
              </SectionIntro>
            </div>
            <ProjectGallery>
              {data.projectGallery?.nodes.map((item, index) => (
                <div key={`portfolio item-${index}`} className="mb-4">
                  <GatsbyImage
                    image={item.childImageSharp.gatsbyImageData}
                    alt={serviceData.projects![0].images[index].alt}
                  />
                </div>
              ))}
            </ProjectGallery>
          </div>
        </section>
      ) : null}

      <section id="contact" className="odd:bg-gray-50">
        <div className="container">
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-4/12 w-full mb-6 sm:mb-0">
              <h3 className="sm:text-3xl text-2xl">Contact Us</h3>
              <ul>
                <ContactInfoItem icon={LocationOnOutlined} title="Address">
                  <li>{contactInfo.address.street}</li>
                  <li>
                    <span className="mr-1">{contactInfo.address.city}</span>
                    <span>{contactInfo.address.zipCode}</span>
                  </li>
                  <li>{contactInfo.address.state}</li>
                </ContactInfoItem>
                <ContactInfoItem icon={EmailOutlined} title="Email">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`mailto:${contactInfo.email}`}
                    >
                      {contactInfo.email}
                    </a>
                  </li>
                </ContactInfoItem>
                <ContactInfoItem icon={PhoneAndroidOutlined} title="Phone">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`tel:${contactInfo.phone}`}
                    >
                      {formatPhoneNumber(contactInfo.phone)}
                    </a>
                  </li>
                </ContactInfoItem>
              </ul>
            </div>
            <div className="lg:w-5/12 sm:w-6/12 w-full">
              <h3 className="sm:text-3xl text-2xl">Send us a message</h3>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicePageTemplate;

export const pageQuery = graphql`
  query(
    $projectGallery: [String!]
    $imagesAboutService: [String!]
    $imagesExpertise: String!
  ) {
    projectGallery: allFile(filter: { base: { in: $projectGallery } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
    imagesAboutService: allFile(filter: { base: { in: $imagesAboutService } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
    imagesExpertise: allFile(filter: { base: { eq: $imagesExpertise } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  }
`;
